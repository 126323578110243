<template>
    <div class="modal__input-area">
        <image-upload :add="true" @get-image="setImage"/>
        <div class="modal__input-container">
            <input v-model="name" id="id-name" class="modal__input" placeholder=" " type="text" ref="firstInput">
            <label class="modal__label" for="id-name">name</label>
        </div>
		
        <div class="modal__input-container">
            <input v-model="location" id="id-location" class="modal__input" placeholder=" " type="text">
            <label class="modal__label" for="id-location">location</label>
        </div>				 
		<div class="modal__input-container">
            <textarea v-model="description" id="id-description" class="modal__description" placeholder=" "></textarea>
            <label class="modal__label" for="id-description">Description</label>
        </div>
		
        <div class="modal__buttons-container">
            <button @click="createBranch" class="modal__button" :disabled="!validateRequired" >
                <div v-if="checking"><filling-circle :size="16" /></div>
                <div v-else style="padding-left:1em;">Add</div>
            </button>
        </div>  

	</div>
</template>

<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import inputValidationMixin from '@/components/Modals/ModalInputs/InputValidationMixin.js'

export default {
    components:{ ImageUpload,FillingCircle },
    data(){
        return {
            checking: false,
            image: null,
            name:'',
            location:'',
            description: '',
        }
    },
    mixins:[ inputValidationMixin ],
    methods:{
        setImage(image){
            this.image = image
        },
        createBranch(){
            if ( this.validateRequired ){
                this.checking = true
                this.$store.dispatch({
                    type:'branches/createBranch',
                    name: this.name,
                    location: this.location,
                    description: this.description,
                    ...(this.image && {logo:this.image})

                }).then(()=>{
                    this.checking = false
                    this.$notify({
                        title: `Branch created!`,
                        text: `You successfully created a branch`,
                        type:"success"
                    })
                    this.$router.go(-1)

                }).catch(error=>{
                    this.checking = false

                    this.$notify({
                        title: "Login Error",
                        text: error.response,
                        type:"error"
                    })
                    console.log(error.response)
                })
            }
           
        }
    },
    mounted(){
		this.$refs.firstInput.focus();
	}
}
</script>