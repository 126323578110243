<template>
    <div class="modal-container">
		<div class="modal">
			<div class="modal__title">
				<h1>Add New Branch</h1> 
				<button class="modal__close" @click="closeModal">
					<img class="modal__close-image" src="@/assets/images/close.svg" alt="">
				</button>
			</div>
			<branch-input />
		</div>
	</div>
</template>
<script>
import BranchInput from '@/components/Branches/modals/Branches.vue'

export default {
	components:{ BranchInput },
    props:{
        name: String,
    },
	methods:{
		closeModal(){
			this.$router.go(-1)
		}
	},

}
</script>

