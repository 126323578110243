import { isEmpty } from '@/helpers/FormValidation.js'
export default {
    computed:{
        validateNameInput(){
            return !isEmpty(this.name) 
        },
        validateLocationInput(){
            return !isEmpty(this.location)
        },
        validateRequired(){
            return this.validateNameInput && this.validateLocationInput
        }
    },
}